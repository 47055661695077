import React, { useState } from 'react';
import { graphql, Link } from 'gatsby';
import Layout from '../components/Layout';
import Section from '../components/Section';
import { Container, Row, Column } from '../components/Grid';
import Action from '../components/Resource/Action';
import Button from '../components/Button';
import Video from '../components/Video';
import Image from '../components/Image';
import Icon from '../components/Icon';
import classNames from 'classnames';
import { LANGUAGE } from '../utils/constants';

const CareerProfile = ({ data, pageContext: { orderLookup, relatedMap } }) => {
  const profile = data.allCareerProfilesYaml.edges[0].node;
  const sortIndex = parseInt(orderLookup[profile.parent.name]);
  const maxCareerIndex = parseInt(orderLookup['max']);
  const [language, setLanguage] = useState('English');
  const [dropdownOpen, setDropdownOpen] = useState(null);

  const generateCareerLinks = () => {
    let links = [];

    if (profile.aboutPdfEnglish) {
      links.push({
        label: `${LANGUAGE.en}`,
        download: `${profile.aboutPdfEnglish}`,
        downloadCloseOnClick: true,
      });
    }

    if (profile.aboutPdfFrench) {
      links.push({
        label: `${LANGUAGE.ca}`,
        download: `${profile.aboutPdfFrench}`,
        downloadCloseOnClick: true,
      });
    }

    return links;
  };

  const generateStudentActivationLinks = () => {
    let links = [];

    if (profile.studentActivationPdfEnglish) {
      links.push({
        label: `${LANGUAGE.en}`,
        download: `${profile.studentActivationPdfEnglish}`,
        downloadCloseOnClick: true,
      });
    }

    if (profile.studentActivationPdfFrench) {
      links.push({
        label: `${LANGUAGE.ca}`,
        download: `${profile.studentActivationPdfFrench}`,
        downloadCloseOnClick: true,
      });
    }

    return links;
  };

  const renderCurrentVideo = () => {
    let vidSrc = profile.englishSrc;

    if (language === 'English') {
      vidSrc = profile.englishSrc;
    } else if (language === 'French') {
      vidSrc = profile.frenchSrc;
    }

    return (
      <Video
        guidSrc={vidSrc}
        poster={profile.videoPoster}
        title={profile.title}
      />
    );
  };

  const languageClick = (lang) => {
    setLanguage(lang);
    setDropdownOpen((state) => !state);
  };

  return (
    <Layout title={`${profile.title} | Career Profiles`}>
      <Section>
        <Container>
          <Row style={{ marginBottom: '0' }}>
            <Column>
              <p>
                <strong>
                  <a href="/career-profiles">
                    &lsaquo; View all career profiles
                  </a>
                </strong>
              </p>
              <h1>{profile.title}</h1>
              <div className="career-profile__video-wrapper">
                {sortIndex > 1 && (
                  <Link
                    className="career-profile__switch-button career-profile__prev-button"
                    to={`/career-profiles/${orderLookup[sortIndex - 1]}`}
                  >
                    <Icon name="arrowleft" className="callout__icon" />
                  </Link>
                )}
                {renderCurrentVideo()}
                {sortIndex < maxCareerIndex && (
                  <Link
                    className="career-profile__switch-button career-profile__next-button"
                    to={`/career-profiles/${orderLookup[sortIndex + 1]}`}
                  >
                    <Icon name="arrowright" className="callout__icon" />
                  </Link>
                )}
              </div>
            </Column>
          </Row>
          <Row className="career-profile__dropdown-container">
            {profile.showLanguageDropdown && (
              <div className="episode-player-dropdown__container">
                <Button
                  onClick={() => setDropdownOpen((state) => !state)}
                  className="resource__dropdown-toggle"
                >
                  {language} &nbsp;{' '}
                  <Icon
                    name="caratdown"
                    className={classNames(
                      'resource__dropdown-carat',
                      dropdownOpen && 'resource__dropdown-carat--open'
                    )}
                  />
                </Button>
                {dropdownOpen && (
                  <div className="resource__dropdown-items career-profile__dropdown-items">
                    <div className="resource__action">
                      <Button
                        onClick={() => languageClick('English')}
                        link
                        className="united-states-flag"
                      >
                        {LANGUAGE.en}
                      </Button>
                    </div>
                    <div className="resource__action">
                      <Button
                        onClick={() => languageClick('French')}
                        link
                        className="canada-flag"
                      >
                        {LANGUAGE.ca}
                      </Button>
                    </div>
                  </div>
                )}
              </div>
            )}
          </Row>
          <Row>
            <Column size={8}>
              <h2>{profile.introHeader}</h2>
              <p dangerouslySetInnerHTML={{ __html: profile.introParagraph }} />
              <ul className="career-profile__quicklinks">
                <li>
                  <a href="#about" className="text-bold">
                    About
                  </a>
                </li>
                <li>
                  <a href="#evaluate-interest" className="text-bold">
                    Evaluate Student Interest
                  </a>
                </li>
                <li>
                  <a href="#activate-interest" className="text-bold">
                    Activate Student Interest
                  </a>
                </li>
              </ul>
              <h3 id="#about">About</h3>
              <p dangerouslySetInnerHTML={{ __html: profile.about }} />
              <div className="resource__actions">
                <Action
                  dropdown={true}
                  label={profile.title}
                  actions={generateCareerLinks()}
                />
              </div>
              <h3 id="evaluate-interest">Evaluate Student Interest</h3>
              <p dangerouslySetInnerHTML={{ __html: profile.evaluateIntro }} />
              <ul className="bullets">
                {profile.evaluateChecklist.map((item) => {
                  return <li key={item}>{item}</li>;
                })}
              </ul>
              <h3 id="activate-interest">Activate Student Interest</h3>
              <p dangerouslySetInnerHTML={{ __html: profile.activateIntro }} />
              <ul className="bullets">
                {profile.activateChecklist.map((item) => {
                  return <li key={item}>{item}</li>;
                })}
              </ul>
              <div className="resource__actions">
                <Action
                  dropdown={true}
                  label="Student Activation"
                  actions={generateStudentActivationLinks()}
                />
              </div>
            </Column>
            <Column size={3} offset={1}>
              <Image
                filename={profile.quoteImage}
                className="quote__headshot"
              />
              <div className="quote__text-wrapper">
                <p
                  className="quote__text"
                  dangerouslySetInnerHTML={{ __html: profile.quote }}
                />
                <p
                  className="quote__attribution"
                  dangerouslySetInnerHTML={{ __html: profile.quoteAttribution }}
                />
              </div>
            </Column>
          </Row>
        </Container>
      </Section>
      <Section>
        <Container>
          <Row>
            <Column>
              <h2>Additional Careers to explore</h2>
              <p
                dangerouslySetInnerHTML={{
                  __html: profile.additionalCareersParagraph,
                }}
              />
            </Column>
          </Row>
          <Row>
            <Column size={6}>
              {profile.related[0] && (
                <>
                  <Image
                    filename={relatedMap[profile.related[0]].relatedImage}
                  />
                  <h4>{relatedMap[profile.related[0]].title}</h4>
                  <p
                    dangerouslySetInnerHTML={{
                      __html: relatedMap[profile.related[0]].shortDescription,
                    }}
                  />
                  <Button to={`/career-profiles/${profile.related[0]}`}>
                    Learn More
                  </Button>
                </>
              )}
            </Column>
            <Column size={6}>
              {profile.related[1] && (
                <>
                  <Image
                    filename={relatedMap[profile.related[1]].relatedImage}
                  />
                  <h4>{relatedMap[profile.related[1]].title}</h4>
                  <p
                    dangerouslySetInnerHTML={{
                      __html: relatedMap[profile.related[1]].shortDescription,
                    }}
                  />
                  <Button to={`/career-profiles/${profile.related[1]}`}>
                    Learn More
                  </Button>
                </>
              )}
            </Column>
          </Row>
        </Container>
      </Section>
    </Layout>
  );
};

export default CareerProfile;

export const query = graphql`
  query CareerTemplateQuery($id: String!) {
    allCareerProfilesYaml(filter: { id: { eq: $id } }) {
      edges {
        node {
          title
          sortIndex
          showLanguageDropdown
          about
          additionalCareersParagraph
          activateIntro
          activateChecklist
          aboutPdfEnglish
          aboutPdfFrench
          aboutPdfSize
          evaluateIntro
          evaluateChecklist
          introHeader
          introParagraph
          related
          studentActivationPdfEnglish
          studentActivationPdfFrench
          studentActivationPdfSize
          videoPoster
          englishSrc
          frenchSrc
          quoteImage
          quote
          quoteAttribution
          parent {
            ... on File {
              name
            }
          }
        }
      }
    }
  }
`;
